<template>
  <div>
    <div v-if="load_comp">
      <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
        <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-data-table
        :headers="headers"
        :items="add_tenant_list"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Add Tenant</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>

            <span style="width: 20px"></span>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">Add Tenant</v-btn>
              </template>
              <v-card>
                <v-form v-model="valid">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.tenantid"
                            persistent-hint
                            :disabled="editedIndex != -1"
                            :hint="editedIndex==-1?'For example :: wws':'For example ::  wws'"
                            :filled="editedIndex==-1?false:true"
                            label="*Enter Tenant ID"
                            :rules="[v => !!v || 'Tenant ID is required']"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.name"
                            persistent-hint
                            :hint="editedIndex==-1?'For example :: WWS':'For example ::  WWS'"
                            label="*Enter Tenant Name"
                            :rules="[v => !!v || 'Tenant name is required']"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="editedItem.domainname"
                            persistent-hint
                            :hint="editedIndex==-1?'For example :: eduplusschool.wws.in':'For example ::  eduplusschool.wws.in'"
                            label="*Enter Domain Name"
                            :rules="[v => !!v || 'Domain name is required']"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="editedItem.logo_path" cols="12" sm="12" md="6">
                          <v-img
                            :src="awspath+editedItem.logo_path+editedItem.logoname"
                            aspect-ratio="1"
                            class="grey lighten-2"
                          ></v-img>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-file-input
                            :rules="rules"
                            placeholder="Pick a logo (file size limit:2MB)"
                            prepend-icon="mdi-camera"
                            label="Logo"
                            show-size
                            v-model="newlogo"
                          ></v-file-input>
                        </v-col>

                        <v-col cols="12" sm="12" md="12" v-if="editedIndex != -1">
                          <v-checkbox
                            dense
                            v-model="editedItem.isactive"
                            color="success"
                            label="Active"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="primary darken-1" @click="save" :disabled="!valid">Save</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.isactive="{ item }">
          <span v-if="item.isactive">
            <v-icon class="mr-2 v-icon-size" color="success">mdi-check-circle-outline</v-icon>
          </span>
          <span v-else>
            <v-icon class="mr-2 v-icon-size" color="error">mdi-close-circle-outline</v-icon>
          </span>
        </template>
        <template v-slot:item.logo_path="{ item }">
          <span v-if="item.logo_path">
            <v-img
              :src="item.logo_path"
              aspect-ratio="1"
              class="grey lighten-2"
            ></v-img>
          </span>
          <span v-else>
            <v-icon class="mr-2 v-icon-size" color="error">mdi-close-circle-outline</v-icon>
          </span>
        </template>
        <template v-slot:item.action="{ item }">
          <div>
            <v-avatar style size="25" class="edit-avatar">
              <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
            </v-avatar>
          </div>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <i class="mdi mdi-36px mdi-spin mdi-loading"></i>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    load_comp: false,
    valid: false,
    awspath: "",
    errorMsg: {
      academic_year_super_master: false
    },
    rules: [
      value =>
        !value || value.size < 2000000 || "Logo size should be less than 2 MB!"
    ],
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    dialog: false,
    headers: [
      {
        text: "Tenant ID",
        align: "left",
        sortable: false,
        value: "tenantid"
      },
      {
        text: "Tenant Name",
        align: "left",
        sortable: false,
        value: "name"
      },
      {
        text: "Domain Name",
        align: "left",
        sortable: false,
        value: "domainname"
      },
      {
        text: "Logo",
        align: "left",
        sortable: false,
        value: "logo_path"
      },
      {
        text: "Active",
        align: "left",
        sortable: true,
        value: "isactive"
      },
      { text: "Actions", value: "action", sortable: false }
    ],
    add_tenant_list: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
      tenantid: "",
      domainname: "",
      logo_path: "",
      logoname: "",
      isactive: true
    },
    defaultItem: {
      id: "",
      name: "",
      tenantid: "",
      domainname: "",
      logo_path: "",
      logoname: "",
      isactive: true
    },
    newlogo: null
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Tenant" : "Edit Tenant";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  mounted() {
    this.initcall();
  },

  methods: {
    initcall() {
      this.awspath = localStorage.getItem("awspath");
      axios
        .post("/Admin/addTenant")
        .then(res => {
          if (res.data.msg == "200") {
            //window.alert(res.data.msg)
            this.add_tenant_list = res.data.tenant_list;
            this.rules = [
              value =>
                !value ||
                value.size < 
                "Logo size should be less than " +
                  this.file_upload_config.max_size / 1000000 +
                  "MB!"
            ];
            this.load_comp = true;
            // console.log(this.rules);
          } else {
            this.showSnackbar("#b71c1c", "Serror Error. Please try again!!!");
          }
        })
        .catch(error => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
        });
    },
    initializeErrorMsgFalse() {
      this.errorMsg.standard = false;
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    editItem(item) {
      this.editedIndex = this.add_tenant_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.newlogo != null) {
        if (this.newlogo.size > 2000000) {
          this.showSnackbar("#b71c1c", "File size exceeded!!!");
          return;
        }
      }
      if (this.editedIndex > -1) {
        if (true) {
          let formData = new FormData();
          formData.append("newlogo", this.newlogo);
          formData.append("tenantid", this.editedItem.tenantid);
          formData.append("name", this.editedItem.name);
          formData.append("domainname", this.editedItem.domainname);
          formData.append("isactive", this.editedItem.isactive);
          axios
            .post("/Admin/editTenant", formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(res => {
              if (res.data.status.code == "SUCCESS") {
                window.location.reload();
                this.showSnackbar("#4caf50", res.data.status.message); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", res.data.status.message); // show snackbar on error
              }
            })
            .catch(error => {
              window.console.log(error);
            });

          this.close();
        }
      } else {
        if (true) {
          let formData = new FormData();
          formData.append("newlogo", this.newlogo);
          formData.append("tenantid", this.editedItem.tenantid);
          formData.append("name", this.editedItem.name);
          formData.append("domainname", this.editedItem.domainname);
          formData.append("isactive", this.editedItem.isactive);
          axios
            .post("/Admin/saveTenant", formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(res => {
              if (res.data.status.code == "SUCCESS") {
                window.location.reload();
                this.showSnackbar("#4caf50", res.data.status.message); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", res.data.status.message); // show snackbar on error
              }
            })
            .catch(error => {
              window.console.log(error);
            });

          this.close();
        }
      }
    } // end of save()
  }
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
</style>